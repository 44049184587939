<template>
    <v-container fluid class="pt-0">
        <span class="pl-5 pl-md-12 section-title">{{ $t('views.generate.title') }}</span>
        <GenerateForm
            ref="form"
            v-model="generator"
            :loading="loading"
            :isFormValid.sync="isFormValid"
            @submit="submit(generator)"
            @typeLoading="value => typeLoading = value"
            @selected-customer="value => isCustomerSelected = !!value"
            :id="id"
            :document="document"
        >
            <template #table-actions>
                <div class="mx-md-4 pb-6">
                    <!-- Versão desktop -->
                    <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex justify-end">
                        <v-btn @click="$router.go(-1)" class="action-button" :disabled="loading" :label="$t('actions.back')" text color="gray">
                            {{ $t('actions.back') }}
                        </v-btn>

                        <v-btn depressed class="action-button" type="submit" :loading="loading" :disabled="!isFormValid || !hasSpentValue" :label="$t('views.generate.generate')" color="primary">
                            {{ $t('views.generate.generate') }}
                        </v-btn>
                    </div>
                    <!-- Versão mobile -->
                    <div v-else class="mx-4">
                        <v-btn depressed class="action-button-mobile" type="submit" :loading="loading" :disabled="!isFormValid || !hasSpentValue" :label="$t('views.generate.generate')" color="primary">
                            {{ $t('views.generate.generate') }}
                        </v-btn>

                        <v-btn @click="$router.go(-1)" class="mt-2 action-button-mobile" :disabled="loading" :label="$t('actions.back')" text color="gray">
                            {{ $t('actions.back') }}
                        </v-btn>
                    </div>
                </div>
            </template>
        </GenerateForm>

        <v-alert type="error" v-if="errorMessage" class="mb-0 mt-3" transition="fade-transition">
            {{ errorMessage }}
        </v-alert>
    </v-container>
</template>

<script>
import GenerateForm         from '@/components/forms/GenerateForm'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import Generator            from '@/models/Generator'

export default {
    name: 'GenerateView',
    mixins: [ HasErrorHandlerMixin ],
    props: {
        id: String,
        document: String,
    },
    data: vm => ({
        isFormValid : false,
        loading     : false,
        generator   : new Generator({ amount: 1 }),
        errorMessage: null,
        typeLoading : false,
    }),
    components: {
        GenerateForm,
    },
    methods: {
        async submit(generator) {
            if (!this.isFormValid || !generator)
                return

            this.loading = true
            let errType = ''
            let res = await generator.generate()
                .catch(this.preErrorHandler)
            this.loading = false

            if (!res)
                return

            this.$bus.$emit('message', this.$t('views.generate.success'), 'success')
            this.$router.push({ name: 'customers.details', params: { id: generator.customer_id, document: generator.customer_document } })
        },
        preErrorHandler(e) {
            let status = this.$lodash.get(e, 'response.status')

            if (e.message == 'Network Error') {
                this.errorMessage = this.$t('views.generate.errors.network_error')
                return
            }

            if (status == 422) {
                let errorType = this.$lodash.get(e, 'response.data.type')

                let errorMessages = []
                this.errorMessage = errorMessages[errorType] || this.$t('views.generate.errors.generic')

                return
            }

            this.errorMessage = this.$t('views.generate.errors.generic')

            this.errorHandler(e)
        },
    },
    computed: {
        hasSpentValue() {
            return this.generator.products.some(product => product.value > 0)
        },
    },
}
</script>

<style lang="scss" scoped>
.action-button {
    width: 140px;
}

.action-button-mobile {
    width: 100%;
}
</style>
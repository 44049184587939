var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "GCCommonForm",
    _vm._g(
      _vm._b(
        {
          ref: "form",
          attrs: { formType: "generate", id: _vm.id, document: _vm.document },
          on: {
            "update:isFormValid": _vm.updateIsFormValid,
            typeLoading: function(value) {
              return _vm.$emit("typeLoading", value)
            }
          },
          scopedSlots: _vm._u(
            [
              {
                key: "table-actions",
                fn: function() {
                  return [_vm._t("table-actions")]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        "GCCommonForm",
        _vm.attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
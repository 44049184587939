var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0", attrs: { fluid: "" } },
    [
      _c("span", { staticClass: "pl-5 pl-md-12 section-title" }, [
        _vm._v(_vm._s(_vm.$t("views.generate.title")))
      ]),
      _c("GenerateForm", {
        ref: "form",
        attrs: {
          loading: _vm.loading,
          isFormValid: _vm.isFormValid,
          id: _vm.id,
          document: _vm.document
        },
        on: {
          "update:isFormValid": function($event) {
            _vm.isFormValid = $event
          },
          "update:is-form-valid": function($event) {
            _vm.isFormValid = $event
          },
          submit: function($event) {
            return _vm.submit(_vm.generator)
          },
          typeLoading: function(value) {
            return (_vm.typeLoading = value)
          },
          "selected-customer": function(value) {
            return (_vm.isCustomerSelected = !!value)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "table-actions",
            fn: function() {
              return [
                _c("div", { staticClass: "mx-md-4 pb-6" }, [
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "div",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "action-button",
                              attrs: {
                                disabled: _vm.loading,
                                label: _vm.$t("actions.back"),
                                text: "",
                                color: "gray"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$router.go(-1)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("actions.back")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "action-button",
                              attrs: {
                                depressed: "",
                                type: "submit",
                                loading: _vm.loading,
                                disabled:
                                  !_vm.isFormValid || !_vm.hasSpentValue,
                                label: _vm.$t("views.generate.generate"),
                                color: "primary"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("views.generate.generate")) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "mx-4" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "action-button-mobile",
                              attrs: {
                                depressed: "",
                                type: "submit",
                                loading: _vm.loading,
                                disabled:
                                  !_vm.isFormValid || !_vm.hasSpentValue,
                                label: _vm.$t("views.generate.generate"),
                                color: "primary"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("views.generate.generate")) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-2 action-button-mobile",
                              attrs: {
                                disabled: _vm.loading,
                                label: _vm.$t("actions.back"),
                                text: "",
                                color: "gray"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$router.go(-1)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("actions.back")) + " ")]
                          )
                        ],
                        1
                      )
                ])
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.generator,
          callback: function($$v) {
            _vm.generator = $$v
          },
          expression: "generator"
        }
      }),
      _vm.errorMessage
        ? _c(
            "v-alert",
            {
              staticClass: "mb-0 mt-3",
              attrs: { type: "error", transition: "fade-transition" }
            },
            [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }